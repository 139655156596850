import React, { useState, useEffect } from 'react';
import APIService from './utils/APIService';
import { getCookies, hasPermission } from './utils/Helper';
import { PEGGY_SITE_ID, USER_PERMISSION } from './constants/const';

const UserContext = React.createContext();

const UserContextProvider = props => {
  const [token, setToken] = useState(getCookies(process.env.REACT_APP_USERTOKEN_COOKIE_NAME));
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [allowBHFrontlist, setAllowBHFrontlist] = useState(true);

  useEffect(() => {
    if (!token) return;
    const fetchUserInfo = async () => {
      if (token) {
        let userRes = await APIService.get(`/user/detail`);
        setUser(userRes.data);

        const site = userRes.data.sites.find(site => site.siteid === PEGGY_SITE_ID);
        setUserRole(site.role);
      }
    };
    fetchUserInfo();
  }, [token]);

  useEffect(() => {
    if (!userRole) return;
    const allowBHFrontlist = hasPermission(userRole, USER_PERMISSION.VIEW_BLOODHOUND_FRONTLIST_TITLES);
    if (allowBHFrontlist) {
      setAllowBHFrontlist(true);
    } else {
      setAllowBHFrontlist(false);
    }
  }, [userRole]);

  /* 
		If user logoff or login, update token from child component
	*/
  const refreshToken = newToken => {
    setToken(newToken);
  };

  return (
    <UserContext.Provider value={{ user, userRole, token, refreshToken, allowBHFrontlist }}>
      {props.children}
    </UserContext.Provider>
  );
};
export { UserContextProvider, UserContext };
