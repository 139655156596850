import { Box, Stack } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import WordWrapButton from './WordWrapButton';

function CustomToolbar({
  children,
  toolbarOptions,
  handleMenuClick,
  csvOptions,
  quickFilterValue,
  filterMode,
  onQuickFilterChange,
}) {
  const columnOption = toolbarOptions.includes('COLUMNS');
  const filterOption = toolbarOptions.includes('FILTER');
  const densityOption = toolbarOptions.includes('DENSITY');
  const exportOption = toolbarOptions.includes('EXPORT');
  const searchOption = toolbarOptions.includes('SEARCH');
  const isFilterOption = columnOption || filterOption || densityOption || exportOption;
  return (
    <GridToolbarContainer>
      <Stack
        sx={{ flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'flex-start', md: 'center' } }}
        spacing={2}
        justifyContent='space-between'
        width='100%'
      >
        <Box>
          {isFilterOption && (
            <Stack spacing={1} direction='row' flexWrap={{ xs: 'wrap', sm: 'nowrap' }}>
              {columnOption && <GridToolbarColumnsButton />}
              {filterOption && <GridToolbarFilterButton />}
              {densityOption && <WordWrapButton handleMenuClick={handleMenuClick} />}
              {exportOption && (
                <GridToolbarExport csvOptions={csvOptions} printOptions={{ disableToolbarButton: true }} />
              )}
            </Stack>
          )}
        </Box>
        {/* This is custom toolbar  */}
        {children && <Box width='100%'>{children}</Box>}
        {searchOption && (
          <Box width='240px' minWidth='240px'>
            {filterMode === 'server' ? (
              <GridToolbarQuickFilter value={quickFilterValue} onChange={onQuickFilterChange} />
            ) : (
              <GridToolbarQuickFilter />
            )}
          </Box>
        )}
      </Stack>
    </GridToolbarContainer>
  );
}

export default CustomToolbar;
