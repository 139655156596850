import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import RenderSpinner from 'src/components/Spinner';
import { UserContext } from 'src/UserContextProvider';

const requirePermission = requiredPermission => WrappedComponent => {
  return props => {
    const { userRole } = useContext(UserContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if (userRole) {
        setLoading(false);
      }
    }, [userRole]);

    // If still loading, you might show a loading spinner or placeholder
    if (loading) {
      return <RenderSpinner />; // Replace with a spinner if needed
    }

    const userPermissions = userRole?.roleroutepermission?.map(permission => permission.route);

    if (!userRole || (requiredPermission && !userPermissions.includes(requiredPermission))) {
      return <Navigate to='/access-denied' />;
    }

    return <WrappedComponent {...props} />;
  };
};

const PrivateRoute = ({ permission, component }) => {
  const WrappedComponent = requirePermission(permission)(component);
  return <WrappedComponent />;
};

export default PrivateRoute;
