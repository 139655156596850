export const style = variant => ({
  '&.MuiDataGrid-root': {
    fontFamily: 'lucidaSansUnicode, lucidaGrande, Sans-Serif',
    fontSize: '12px',
    // boxShadow: 2,
    // border: 2,
    // borderColor: 'primary.light',
    '& .MuiDataGrid-pinnedColumnHeaders': {
      minHeight: 'unset !important',
      maxHeight: 'unset !important',
      height: '100%',
      '& > div:first-of-type': {
        height: '100% !important',
      },
    },
    '& .MuiDataGrid-columnHeaders': {
      minHeight: 'unset !important',
      maxHeight: 'unset !important',
    },
    '& .MuiDataGrid-columnHeaderRow': {
      height: 'auto',
    },
    '& .MuiDataGrid-toolbarContainer': {
      padding: '8px',
    },
    '& .MuiDataGrid-columnHeader': {
      backgroundColor: variant === 'secondary' ? '#4287f5' : 'black',
      color: 'white',
      height: 'auto !important',
      '.MuiDataGrid-sortIcon': {
        fill: 'white',
      },
      '&:hover': {
        '& .MuiDataGrid-columnHeaderTitle': {
          whiteSpace: 'nowrap',
        },
      },
      '& .MuiButtonBase-root.MuiCheckbox-root': {
        padding: 0.3,
        '& .MuiSvgIcon-root': {
          width: '16px',
          height: '16px',
          fill: 'white',
        },
      },
    },
    //Based on https://github.com/mui/mui-x/issues/898
    '& .MuiDataGrid-columnHeaderTitle': {
      whiteSpace: 'break-spaces',
      lineHeight: 1.3,
      padding: '16px 0',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer': {
      pl: 1,
    },
    '& .MuiDataGrid-menuIconButton': {
      color: 'white',
    },
    '& .MuiDataGrid-columnHeaderCheckbox .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
      color: 'white',
    },
    '& .MuiDataGrid-row': {
      cursor: 'pointer',
      '&.highlight-row': {
        backgroundColor: 'rgb(166, 247, 196)',
      },
    },
    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
      display: 'flex',
      alignItems: 'center',
      py: 0.5,
    },
    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
      display: 'flex',
      alignItems: 'center',
      py: 1,
    },
    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
      display: 'flex',
      alignItems: 'center',
      py: 1.5,
    },
    '& .MuiTablePagination-displayedRows': {
      marginTop: '1em',
      marginBottom: '1em',
    },
    '& .MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel': {
      marginTop: '1em',
      marginBottom: '1em',
    },
    //Check box selection style
    '& .MuiDataGrid-cellCheckbox': {
      '& .MuiButtonBase-root': {
        padding: 0.3,
        '& .MuiSvgIcon-root': {
          width: '16px',
          height: '16px',
        },
      },
    },
  },
});
