import dayjs from 'dayjs';
import { COMPARISON_OPERATOR } from 'src/constants/const';
import APIService from 'src/utils/APIService';
import { validateEmailFormat } from 'src/utils/Helper';
import { getOrdinalSuffix } from 'src/utils/TriggerTitleHelper';
import ormToast, { TOAST_TYPE } from 'src/utils/ormToast';

export const validateTriggerTitleForm = values => {
  let isError = false;
  let errorsObject = {};
  if (!values.name) {
    errorsObject.name = 'Field is required';
    isError = true;
  } else {
    errorsObject.name = '';
  }
  if (!values.minTitleCount && values.minTitleCount !== 0) {
    errorsObject.minTitleCount = 'Field is required';
    isError = true;
  } else {
    errorsObject.minTitleCount = '';
  }

  if (values.minTitleCount && values.maxTitleCount) {
    if (values.minTitleCount > values.maxTitleCount) {
      errorsObject.minTitleCount = 'The title count should be less than to max.';
      isError = true;
    }
  }
  if (!values.schedule.type) {
    errorsObject.frequencyType = 'Please select schedule type';
    isError = true;
  }
  if (values.schedule.type === 'Weekly' || values.schedule.type === 'Bi-Weekly') {
    if (!values.schedule.weekDay?.value) {
      errorsObject.frequencyWeekDay = 'Please select days';
      isError = true;
    }
  }
  if (values.schedule.type === 'Monthly') {
    if (!values.schedule.fixedDay) {
      errorsObject.frequencyFixedDay = 'Field is required';
      isError = true;
    }
  }
  if (values.destination?.orionPostUpdate) {
    const site = values.destination.orionPostUpdate.site ?? {};
    const postDetail = values.destination.orionPostUpdate.postDetail ?? {};

    if (Object.keys(site).length > 0 && Object.keys(postDetail).length === 0) {
      errorsObject.orionPostUpdate =
        'Push Trigger Titles to Orion Post Configuration missing slug. If you do not want to use this functionality, please uncheck site';
    }
  }
  if (values.destination.campaignType && values.destination.campaignName?.trim()) {
    if (
      !values.destination?.startingDate?.numberOfDaysForTriggerSchedule &&
      !values.destination?.startingDate?.occurOn?.value &&
      !values.destination?.startingDate?.occurDay
    ) {
      errorsObject.destinationStartingNumberOfDays = 'Field is reqreuid';
      errorsObject.destinationStartingOccurOn = 'Please select week';
      errorsObject.destinationStartingOccurDay = 'Please select day';
      isError = true;
    }
    if (values.schedule.type !== 'Daily' && values.destination?.startingDate?.numberOfDaysForTriggerSchedule) {
      if (!values.destination?.endingDate?.relativeEndDays && !values.destination?.endingDate?.endOfMonth) {
        errorsObject.destinationEndingRelativeDays = 'Field is reqreuid';
        errorsObject.destinationEndofMonth = 'Field is required';
        isError = true;
      }
    }
  }
  if (values.notifications.fail) {
    const emails = values.notifications.fail.split(',');
    emails.forEach(email => {
      if (!validateEmailFormat(email?.trim())) {
        errorsObject.notificationFail = 'Please enter valid email Id';
        isError = true;
      }
    });
  }
  if (values.notifications.success) {
    const emails = values.notifications.success.split(',');
    emails.forEach(email => {
      if (!validateEmailFormat(email?.trim())) {
        errorsObject.notificationSuccess = 'Please enter valid email Id';
        isError = true;
      }
    });
  }
  if (
    values.preventTitleRepetition &&
    (!values.numberOfRunsToPreventTitleRepetition || values.numberOfRunsToPreventTitleRepetition === '0')
  ) {
    errorsObject.numberOfRunsToPreventTitleRepetition = 'Required';
    isError = true;
  } else {
    errorsObject.numberOfRunsToPreventTitleRepetition = '';
  }
  if (values.sorting.asc?.length > 0 && values.sorting.desc?.length > 0) {
    const isDuplicate = values.sorting.asc.some(ascValue =>
      values.sorting.desc.some(descValue => descValue.value === ascValue.value)
    );
    if (isDuplicate) {
      ormToast('Sorting should not be same in asc and desc order', TOAST_TYPE.ERROR);
      isError = true;
    }
  }
  return { isError, errorsObject };
};

export const checkRenderFilterValue = data => {
  let isError = false;
  for (let filters of data) {
    for (let childFilters of filters.childFilters) {
      const { type, value, controlType } = childFilters;
      if (type === 'Boolean' || !controlType?.isRequired) continue;
      if (!value || (typeof value === 'string' && !value?.trim())) {
        isError = true;
        break;
      }
      if (value && Array.isArray(value) && value?.length === 0) {
        isError = true;
        break;
      }
      if (value && Object.keys(value)?.length === 0) {
        isError = true;
        break;
      }
    }
    if (isError) break;
  }

  return isError;
};

// This function loop through post processing data and check 100% criteria
export const validatePostProcessingValue = data => {
  let isError = false;
  for (let key in data) {
    if (data[key]?.length > 0) {
      let total = 0;
      for (let filter of data[key]) {
        if (filter.fieldValue) {
          total += filter.fieldValue;
        }
      }
      if (total !== 100) {
        isError = true;
      }
    }
    if (isError) break;
  }

  return isError;
};

// This function take schedule(frequency value) and and return date based on setup
export const findNextRunDate = values => {
  const EST_TIME_FORMAT = 'MM/DD/YYYY hh:mm A [EST]';
  const hour = dayjs(values.time).isValid() ? dayjs(values.time).get('hour') : values.time.hour;
  const minute = dayjs(values.time).isValid() ? dayjs(values.time).get('minute') : values.time.minute;
  let nextExecution;
  switch (values.type) {
    case 'Daily': {
      nextExecution = dayjs().hour(hour).minute(minute);
      if (nextExecution <= dayjs()) {
        nextExecution = nextExecution.add(1, 'day');
      }
      break;
    }
    case 'Weekly':
    case 'Bi-Weekly': {
      if (values.weekDay) {
        nextExecution = getNextDateUsingDayAndTime(values.weekDay?.value, hour, minute);
      }
      break;
    }
    case 'Monthly': {
      if (values.fixedDay) {
        const currentDate = dayjs().get('date');
        if (currentDate < Number(values.fixedDay)) {
          nextExecution = dayjs().date(values.fixedDay).hour(hour).minute(minute);
        } else {
          nextExecution = dayjs().add(1, 'M').date(values.fixedDay).hour(hour).minute(minute);
        }
      }
      break;
    }
    default: {
      break;
    }
  }
  return nextExecution?.hour(hour).minute(minute).format(EST_TIME_FORMAT) || '';
};

/**
 * Get the next date with time based on the target day, hour, and minute.
 *
 * @param {number} targetDay - The target day of the week (0-6, where 0 is Sunday and 6 is Saturday).
 * @param {number} targetHour - The target hour of the day (0-23).
 * @param {number} targetMinute - The target minute of the hour (0-59).
 * @returns {dayjs.Dayjs} The next date and time that matches the target day, hour, and minute.
 */
export const getNextDateUsingDayAndTime = (targetDay, targetHour, targetMinute) => {
  // Get the current date and time
  const now = dayjs();

  // Get the current day, hour, and minute
  const currentDay = now.day();
  const currentHour = now.hour();
  const currentMinute = now.minute();

  // Calculate the difference in days between the current day and the target day
  let dayDifference = targetDay - currentDay;

  // If the target day is earlier in the week than the current day,
  // or it's the same day but the target time has already passed,
  // add 7 days to the day difference to get the next occurrence of the target day
  if (
    dayDifference < 0 ||
    (dayDifference === 0 &&
      (currentHour > targetHour || (currentHour === Number(targetHour) && currentMinute >= Number(targetMinute))))
  ) {
    dayDifference += 7;
  }

  // Calculate the next date and time by adding the day difference
  // and setting the target hour, minute, second, and millisecond
  return now.add(dayDifference, 'day').hour(targetHour).minute(targetMinute).second(0).millisecond(0);
};

export const findDestinationStartDateEndDate = (values, scheduleValues) => {
  const { startingDate, endingDate } = values || {};
  let startDate, endDate;
  const nextRunDate = findNextRunDate(scheduleValues);
  if (nextRunDate) {
    // Find Start Date
    if (startingDate.numberOfDaysForTriggerSchedule) {
      startDate = dayjs(nextRunDate).add(startingDate.numberOfDaysForTriggerSchedule, 'day')?.format('MM/DD/YYYY');
      if (endingDate.relativeEndDays) {
        endDate = dayjs(startDate).add(endingDate.relativeEndDays, 'd')?.format('MM/DD/YYYY');
      } else if (endingDate.endOfMonth) {
        const currentDate = dayjs(startDate); // Get the current date
        endDate = currentDate.endOf('month')?.format('MM/DD/YYYY'); // Get the end of the current month
      } else {
        endDate = startDate;
      }
    } else if (startingDate.occurOn?.value && startingDate.occurDay?.value) {
      const occurDay = startingDate.occurDay?.value;
      startDate = findNthWeekdayOfMonth(startingDate.occurOn.value, occurDay, nextRunDate)?.format('MM/DD/YYYY');
      endDate = startDate;
    }
  }
  return { startDate, endDate };
};

export const isDateDifferenceMoreThan90Days = (values, scheduleValues, selectedCampaignType, downPricedCampaigns) => {
  const { startDate, endDate } = findDestinationStartDateEndDate(values, scheduleValues);
  console.log('startDate', startDate);
  console.log('endDate', endDate);
  console.log('selectedCampaignType', selectedCampaignType);
  console.log('downPricedCampaigns', downPricedCampaigns);

  if (!startDate || !endDate) return false;

  const start = dayjs(startDate, 'MM/DD/YYYY');
  const end = dayjs(endDate, 'MM/DD/YYYY');

  return (
    selectedCampaignType && downPricedCampaigns.includes(selectedCampaignType.value) && end.diff(start, 'day') > 90
  );
};

// This function calculates the date of the nth weekday (e.g., 1st Monday, 2nd Tuesday)
// in the current month based on the given week number and day of the week.
function findNthWeekdayOfMonth(weekNumber, dayOfWeek, date) {
  // Get the current date
  let currentDate = dayjs(date).startOf('month');

  // Find the first occurrence of the desired day of the week in the month
  let firstDay = currentDate.day();
  let diff = (dayOfWeek - firstDay + 7) % 7;
  let firstOccurrence = currentDate.add(diff, 'day');

  // Calculate the date of the nth weekday in the month
  let nthWeekday = firstOccurrence.add((weekNumber - 1) * 7, 'day');
  if (nthWeekday < dayjs(date)) {
    nthWeekday = firstOccurrence
      .add(1, 'month')
      .startOf('month')
      .add((weekNumber - 1) * 7, 'day');
  }

  return nthWeekday;
}

export const getDestinationInfoText = (destination, schedule, minTitleCount, maxTitleCount) => {
  if (destination) {
    const count = maxTitleCount ? `${minTitleCount} - ${maxTitleCount}` : 'All';
    const { campaignType, campaignName } = destination || {};
    const campaignTypeName = campaignType?.text;
    const { startDate, endDate } = findDestinationStartDateEndDate(destination, schedule);
    let campaignNameDisplay = campaignName || 'Find All campaign name with same campaign type';

    let action;
    switch (destination.type) {
      case 'CREATE':
        action = 'create a new';
        break;
      case 'UPDATE':
        action = 'update existing';
        break;
      case 'UPDATE_AND_CREATE':
        action = 'update/create a';
        break;
      case 'CLONE':
        action = 'create a clone of an existing';
        break;
      default:
        action = '';
    }

    let campaignDisplayName;
    if (destination.type === 'CLONE') {
      campaignDisplayName = `<b>Trigger_Clone_${campaignName || campaignTypeName}</b>`;
    } else if (destination.type === 'CREATE') {
      campaignDisplayName = `<b>Trigger_${campaignName || campaignTypeName}</b>`;
    } else {
      campaignDisplayName = `<b>${campaignNameDisplay}</b>`;
    }

    let postUpdateMsg = '',
      linkUrl = ``;
    if (
      destination?.orionPostUpdate &&
      Object.keys(destination?.orionPostUpdate?.site ?? {}).length > 0 &&
      Object.keys(destination?.orionPostUpdate?.postDetail ?? {}).length > 0
    ) {
      if (process.env.REACT_APP_ORION_ENV === 'local' || process.env.REACT_APP_ORION_ENV === 'development')
        linkUrl = `http://local.${destination.orionPostUpdate.site.domain}/${destination.orionPostUpdate.postDetail.value}`;
      else if (process.env.REACT_APP_ORION_ENV === 'staging')
        linkUrl = `https://staging.${destination.orionPostUpdate.site.domain}/${destination.orionPostUpdate.postDetail.value}`;
      else
        linkUrl = `https://${destination.orionPostUpdate.site.domain}/${destination.orionPostUpdate.postDetail.value}`;

      postUpdateMsg = `
      ORION POST --> Your trigger configuration will also update ${linkUrl}
      `;
    }
    return campaignDisplayName && campaignTypeName
      ? `Based on your trigger configuration, the system will ${action} campaign with ${count} titles.<br/>
        Campaign Name: ${campaignDisplayName}<br/> 
        campaign Type : <b>${campaignTypeName}</b><br/>
        Campaign Start Date:  <b>${startDate || '-'}</b><br/>
        Campaign End Date:  <b>${endDate || '-'}</b><br/>
        <hr/>
        ${postUpdateMsg}`
      : postUpdateMsg;
  } else {
    return 'API JSON';
  }
};

export const getFrequencyInfoText = schedule => {
  const time = dayjs(schedule.time).isValid()
    ? dayjs(schedule.time).format('hh:mm A [EST]')
    : dayjs().hour(schedule.time?.hour).minute(schedule.time?.minute).format('hh:mm A [EST]');
  let finalMessage,
    runOn,
    type = 'Daily';
  if (schedule) {
    if (schedule.type === 'Bi-Weekly') type = 'Every Two Week';
    if (schedule.type === 'Weekly') type = 'Every Week';
    if (schedule.type === 'Monthly') type = 'Every Month';

    if (schedule.type === 'Daily') {
      finalMessage = `Your trigger will run ${type} at ${time}`;
    }

    if (schedule.type === 'Weekly' || schedule.type === 'Bi-Weekly') {
      let dys = schedule.weekDay?.label || '-';

      finalMessage = `Your trigger will run on ${dys} ${type} at ${time}`;
    }
    if (schedule.type === 'Monthly') {
      runOn = schedule.fixedDay ? getOrdinalSuffix(schedule.fixedDay) : '-';
      finalMessage = `Your Trigger Titles will run ${runOn} of ${type} at ${time}`;
    }
  }
  return finalMessage;
};

export const sendApproverEmailNotifications = async data => {
  const { id, approvers, triggerName, userName, type, updatedBy, createdBy, nextRunDate } = data;
  try {
    // Define email subject and URL to the trigger review page
    let subject = 'Trigger Approval Required';
    const url = `${window.location.origin}/trigger-titles/${id}`;

    // Determine recipient email(s) based on environment and the type of action
    let recipient = approvers; // Default recipient are the approvers
    let htmlBody = `
      <div>
        <p>Hello Approver,</p>
        <b>${userName}</b> ${type === 'CREATE' ? 'created' : 'edited'} Trigger <b>${triggerName}</b>,
        which requires your review and approval.
        Kindly <a href="${url}">Click Here</a> to review the trigger and approve it.
        <br/>
        <p>Thanks,</p>
      </div>`;

    // If the trigger has been approved, send a different email template to the creator and editor
    if (type === 'APPROVED') {
      subject = 'Trigger Configuration Approved';
      htmlBody = `
        <div>
          <p>Hello User,</p>
          Trigger Admin <b>${userName}</b> approved your trigger configuration. Your trigger is now active and will be executed on the next run date: ${nextRunDate}.
          <br/>
          <br/>
          You can view your trigger configuration <a href="${url}">HERE</a>.
          <br/>
          <br/>
          You can view your trigger result <a href="${url}">HERE</a>.
          <br/>
          <br/>
          <p>Thanks,</p>
        </div>`;
      // Email goes to both the updatedBy and createdBy users
      recipient = [];
      createdBy && recipient.push(createdBy);
      updatedBy && recipient.push(updatedBy);
    }
    console.log('recipient', recipient); //TODO: for debugging. Once testing is done then we can remove this

    // Override recipient to engineering email in local/development environments
    if (process.env.REACT_APP_ORION_ENV === 'local' || process.env.REACT_APP_ORION_ENV === 'development') {
      recipient = process.env.REACT_APP_ORM_ENGINEERING_EMAIL;
    }

    // Prepare request body for the email service
    if (recipient && recipient?.length > 0) {
      const reqBody = {
        to: recipient,
        subject,
        html: htmlBody,
      };

      // Send email request via API service
      await APIService.post('/sendemail', reqBody);
    } else {
      ormToast('Failed to send approval email.', TOAST_TYPE.ERROR);
      console.error('recipient: ', recipient);
    }
  } catch (err) {
    // Handle errors gracefully and log them for debugging
    ormToast('Failed to send the approval email.', TOAST_TYPE.ERROR);
    console.error('Error while sending approval email:', err);
  }
};

// Function to check if the provided string is a valid JSON
function isValidJSON(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

// Function to render content based on the value
export const renderTriggerHistoryCell = ({ value, row }) => {
  // Check if the value is a valid JSON string
  if (isValidJSON(value)) {
    const parsedValue = JSON.parse(value); // Parse the JSON string into an object/array

    if (row.name.includes('Business Rule')) {
      // If parsedValue is an array
      if (Array.isArray(parsedValue)) {
        // If the array has multiple items, map through each one
        return (
          <div>
            {parsedValue.map((item, index) => (
              <div key={item.filterName || index}>
                <b>{item.filterName && `${item.filterName} :`}</b> {getValue(item)}
                <br />
              </div>
            ))}
          </div>
        );
      }

      // If parsedValue is an object (not an array)
      if (typeof parsedValue === 'object' && parsedValue !== null) {
        return (
          <div>
            {Object.entries(parsedValue).map(([key, val]) => (
              <div key={key}>
                <b>{key && key + ' : '}</b>
                {typeof val === 'string' ? val : JSON.stringify(val)}
                <br />
              </div>
            ))}
          </div>
        );
      }

      // If parsedValue is a primitive value (string, number, boolean, etc.)
      return String(parsedValue);
    } else {
      // If parsedValue is an array
      if (Array.isArray(parsedValue)) {
        return parsedValue.map(value => value?.label || value).join(', ');
      } else if (typeof parsedValue === 'object' && parsedValue !== null) {
        return (
          <div>
            {Object.entries(parsedValue).map(([key, val]) => (
              <div key={key}>
                <b>{key && key + ' : '}</b> {typeof val === 'string' ? val : JSON.stringify(val)}
                <br />
              </div>
            ))}
          </div>
        );
      }
    }
  }

  // If the value is not valid JSON, return it as is
  return value;
};

const getValue = data => {
  const { value, comparisonOperator } = data;
  if (value) {
    let filterValue;
    if (typeof value === 'string' || typeof value === 'number') {
      filterValue = value;
    } else if (Array.isArray(value)) {
      filterValue = value.map(v => v?.label || v)?.join(', ');
    } else if (typeof value === 'object') {
      filterValue = value?.label;
    } else {
      filterValue = value;
    }
    return (comparisonOperator ? COMPARISON_OPERATOR[comparisonOperator] : '') + ' ' + filterValue;
  }
  return '';
};
